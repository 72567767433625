<template>
  <div
    class="container noColor"
    v-title
    id="upkeepContracts"
    :data-title="$t('i18n.upkeepContracts')"
  >
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.basicInfo") }}</span>
        <el-button class="fr" type="text" @click="$router.go(-1)">
          {{ $t("i18n.back") }}
        </el-button>
        <el-button
          v-has="'编辑保养合同'"
          class="fr"
          type="text"
          @click="setIsEdit(false)"
        >
          {{ $t("i18n.edit") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-form
          :columns="columns"
          @onSubmit="onSubmit"
          @resetForm="setIsEdit(true)"
        ></jl-form>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.billingInformation") }}</span>
        <el-button
          v-has="'导出财务数据'"
          class="fr"
          type="text"
          @click="exportNotBills"
        >
          {{ $t("i18n.export") }}
        </el-button>
        <el-button v-has="'添加开票记录'" class="fr" type="text">
          {{ $t("i18n.newBillingRecord") }}
        </el-button>
      </template>
      <div class="text item" id="billTable">
        <jl-table
          @init="getBillData"
          :tableData="billData"
          :options="billOptions"
          :columns="billColumns"
          @handleEdit="handleBillEdit"
          @handleDelete="handleBillDelete"
          :operates="billOperates"
          :total="billTotal"
          :height="300"
        >
        </jl-table>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.relatedMaintenanceOrderNo") }}</span>
        <el-button class="fr" type="text" @click="newUpkeepVisible = true">
          {{ $t("i18n.addContract") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-table
          @init="getUpkeepList"
          :tableData="upkeepData"
          :options="elevatorOptions"
          :columns="upkeepColumns"
          @handleDelete="handleUpkeepDelete"
          :operates="upkeepOperates"
          :total="upkeepTotal"
          :height="300"
        >
        </jl-table>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.elevatorList") }}</span>
        <el-button class="fr" type="text" @click="newElevatorVisible = true">
          {{ $t("i18n.addElevator") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-table
          @init="getElevatorList"
          :tableData="elevatorData"
          :options="elevatorOptions"
          :columns="elevatorColumns"
          @handleDelete="handleElevatorDelete"
          :operates="elevatorOperates"
          :total="elevatorTotal"
          :height="300"
        >
        </jl-table>
      </div>
    </el-card>
    <el-dialog
      :title="$t('i18n.addContract')"
      v-model="newUpkeepVisible"
      :close-on-click-modal="false"
      width="80%"
      destroy-on-close
    >
      <jl-table
        @init="getAddConList"
        :tableData="addConData"
        :options="addEleOptions"
        :columns="addConColumns"
        :operates="addConOperates"
        :total="addConTotal"
        @handleSelectionChange="
          (val) => {
            handleSelectionChange(val, 'selectNewData');
          }
        "
        height="500"
      ></jl-table>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.addElevator')"
      v-model="newElevatorVisible"
      :close-on-click-modal="false"
      width="80%"
      destroy-on-close
    >
      <jl-table
        @init="getAddEleList"
        :tableData="addEleData"
        :options="addEleOptions"
        :columns="addEleColumns"
        :operates="addEleOperates"
        :total="addEleTotal"
        @handleSelectionChange="
          (val) => {
            handleSelectionChange(val, 'selectNewData');
          }
        "
        height="500"
      ></jl-table>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
export default {
  name: "UpkeepContrctViewcou",
  components: {
    jlForm,
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    let upkeepType = "20";
    const state = reactive({
      columns: [
        // 需要展示的列
        {
          prop: "upkeepType",
          label: "contractType",
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "upkeep_type",
          view: "20, 30, 40",
          readonly: true,
        },
        {
          prop: "quotationNo",
          label: "quotationNo",
          type: "input",
          show: parseInt(upkeepType) > 10,
          view: "20, 30, 40",
          readonly: true,
        },
        {
          prop: "number",
          label: "equipmentContractNo",
          type: "input",
          show: parseInt(upkeepType) > 10,
          view: "20, 30, 40",
          rules: [{ required: true, message: t("i18n.input") }],
          readonly: true,
        },
        {
          prop: "value",
          label: "contractAmount",
          type: "input",
          show: upkeepType == "20" || upkeepType == "30",
          view: "20, 30",
          readonly: true,
        },
        {
          prop: "areaAttribute",
          label: "regionAttribute",
          type: "select",
          props: { label: "name", value: "id" },
          data: [],
          view: "20, 30, 40",
          readonly: true,
        },
        {
          prop: "maintainerPhone",
          label: "contractInformation",
          type: "input",
          show: upkeepType == "40",
          view: "40",
          readonly: true,
        },
        {
          prop: "stockNo",
          label: "materialNo",
          type: "input",
          show: upkeepType == "20",
          view: "20",
          readonly: true,
        },
        {
          prop: "materialName",
          label: "materialName",
          type: "input",
          show: upkeepType == "20",
          view: "20",
          readonly: true,
        },
        {
          prop: "specification",
          label: "spec",
          type: "input",
          show: upkeepType == "20",
          view: "20",
          readonly: true,
        },
        {
          prop: "unit",
          label: "units",
          type: "input",
          show: upkeepType == "20",
          view: "20",
          readonly: true,
        },
        {
          prop: "counts",
          label: "num",
          type: "input",
          show: upkeepType == "20",
          view: "20",
          readonly: true,
        },
        {
          prop: "quotationPrice",
          label: "quotationPrice",
          type: "input",
          show: upkeepType == "20",
          view: "20",
          readonly: true,
        },
        {
          prop: "quotationTotal",
          label: "totalPrice",
          type: "input",
          show: upkeepType == "20",
          view: "20",
          readonly: true,
        },
        {
          prop: "customerName",
          label: "clientName",
          type: "input",
          show: parseInt(upkeepType) > 10,
          view: "20, 30, 40",
          readonly: true,
        },
        {
          prop: "partsNo",
          label: "accessoriesNumber",
          type: "input",
          show: upkeepType == "20",
          view: "20",
          readonly: true,
        },
        {
          prop: "consignee",
          label: "consignee",
          type: "input",
          show: upkeepType == "20",
          view: "20",
          readonly: true,
        },
        {
          prop: "contactPhone",
          label: "consigneeNo",
          type: "input",
          show: upkeepType == "20" || upkeepType == "30",
          view: "20, 30",
          readonly: true,
        },
        {
          prop: "contactAddress",
          label: "deliveryAddress",
          type: "input",
          show: upkeepType == "20" || upkeepType == "30",
          view: "20, 30",
          readonly: true,
        },
        {
          prop: "paymentDate",
          label: "receivedDate",
          type: "date",
          show: parseInt(upkeepType) > 10,
          view: "20, 30, 40",
          readonly: true,
        },
        {
          prop: "sellNo",
          label: "salesOrderNo",
          type: "input",
          show: upkeepType == "20" || upkeepType == "30",
          view: "20, 30",
          readonly: true,
        },
        {
          prop: "deliveryNo",
          label: "deliveryOrderNo",
          type: "input",
          show: upkeepType == "20" || upkeepType == "30",
          view: "20, 30",
          readonly: true,
        },
        {
          prop: "reformContent",
          label: "renewalContent",
          type: "input",
          show: upkeepType == "30",
          view: "30",
          readonly: true,
        },
        {
          prop: "materialPrice",
          label: "materialQuotation",
          type: "input",
          show: upkeepType == "30",
          view: "30",
          readonly: true,
        },
        {
          prop: "artificialPrice",
          label: "manualQuotation",
          type: "input",
          show: upkeepType == "30",
          view: "30",
          readonly: true,
        },
        {
          prop: "totalPrice",
          label: "totalAmt",
          type: "input",
          show: upkeepType == "30" || upkeepType == "40",
          view: "30, 40",
          readonly: true,
        },
        {
          prop: "quotation",
          label: "quotation",
          type: "input",
          show: upkeepType == "40",
          view: "40",
          readonly: true,
        },
        {
          prop: "consignee",
          label: "onSiteContactPerson",
          type: "input",
          show: upkeepType == "40",
          view: "40",
          readonly: true,
        },
        {
          prop: "supportContent",
          label: "supportedTech",
          type: "input",
          show: upkeepType == "40",
          view: "40",
          readonly: true,
        },
        {
          prop: "manuscript",
          label: "originalcontract",
          type: "upload",
          action: "contracts",
          limit: "1",
          value: [],
          view: "20, 30, 40",
          tip: "只能上传jpg/png/pdf文件",
        },
      ],
      billOptions: {
        isAdd: proxy.$defined.btnPermission("添加开票记录"),
        isEdit: proxy.$defined.btnPermission("编辑开票记录"), // 是否启用编辑功能
        isDelete: proxy.$defined.btnPermission("删除开票记录"), // 是否启用删除功能
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      billTotal: 0,
      billData: [],
      billColumns: [
        {
          prop: "invoiceType",
          label: "invoiceType",
          align: "center",
          type: "switch",
          text: {
            aText: "specialVATInvoice",
            aVal: "20",
            iText: "generalVATInvoice",
            iVal: "10",
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "invoiceParty",
          label: "invoiceParty",
          align: "center",
          type: "input",
        },
        {
          prop: "invoiceIssuer",
          label: "invoiceIssuerContact",
          align: "center",
          type: "input",
        },
        {
          prop: "billNo",
          label: "invoiceNo",
          align: "center",
          type: "input",
        },
        {
          prop: "billValue",
          label: "invoiceAmount",
          align: "center",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "billingDate",
          label: "billingDate",
          align: "center",
          type: "date",
          formatter: (row) => {
            return row.billingDate.split(" ")[0];
          },
        },
        {
          prop: "matters",
          label: "relatedIssues",
          align: "center",
          type: "input",
        },
      ],
      billOperates: {
        width: 140,
        fixed: "right",
        list: [],
      },
      elevatorOptions: {
        isAdd: false,
        isEdit: false, // 是否启用编辑功能
        isDelete: true, // 是否启用删除功能
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      elevatorTotal: 0,
      elevatorData: [],
      elevatorColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/elevator/addEditElevator",
              query: { flag: 1, id: row.id },
            });
          },
        },
        {
          prop: "elevatorVarietyStr",
          label: "elevatorType",
          align: "center",
        },
        {
          prop: "manufacturerName",
          label: "manufacturingCompany",
          align: "center",
        },
        {
          prop: "lastCheckDate",
          label: "lastAnnualInspectionDate",
          align: "center",
        },
        {
          prop: "avgValue",
          label: "singleElevatorUpkeepCost",
          align: "center",
        },
      ],
      elevatorOperates: {
        width: 80,
        fixed: "right",
        list: [],
      },
      newElevatorVisible: false, // 新增电梯弹窗
      addEleData: [],
      addEleTotal: 0,
      addEleColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "alias",
          label: "projectAlias",
          align: "left",
        },
        {
          prop: "projectName",
          label: "projectName",
          align: "left",
          width: 460,
          search: true,
          type: "input",
        },
        {
          prop: "elevatorType",
          label: "elevatorType",
          align: "center",
        },
      ],
      addEleOperates: {
        width: 100,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "addElevator",
            icon: "el-icon-plus",
            class: "add",
            show: true,
            type: "outer",
            method: () => {
              addNewElevator();
            },
          },
        ],
      },
      addEleOptions: {
        isAdd: false,
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      upkeepTotal: 0,
      upkeepData: [],
      upkeepColumns: [
        {
          prop: "number",
          label: "relatedMaintenanceOrderNo",
          align: "center",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/upkeepContract/upkeepContractViewupdate",
              query: { id: row.id },
            });
          },
        },
        {
          prop: "ownerShortname",
          label: "ownerNickname",
          align: "center",
        },
        {
          prop: "status",
          label: "contractStatus",
          align: "center",
        },
      ],
      upkeepOperates: {
        width: 140,
        fixed: "right",
        list: [],
      },
      extendId: "",
      newUpkeepVisible: false, // 新增关联保养合同弹窗
      addConData: [],
      addConTotal: 0,
      addConColumns: [
        {
          prop: "number",
          label: "contractNo",
          align: "center",
          search: true,
          type: "input",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/upkeepContract/upkeepContractViewupdate",
              query: { id: row.id },
            });
          },
        },
        {
          prop: "ownerShortname",
          label: "ownerNickname",
          align: "center",
        },
        {
          prop: "status",
          label: "contractStatus",
          align: "center",
        },
      ],
      addConOperates: {
        width: 100,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "addContract",
            icon: "el-icon-plus",
            class: "add",
            show: true,
            type: "outer",
            method: () => {
              addNewContract();
            },
          },
        ],
      },
    });

    const init = async () => {
      getContractInfo(route.query.id);
      areaAttribute();
    };

    const getContractInfo = async (id) => {
      // 获取合同详细信息
      let { data } = await proxy.$api.maintenance.getOtherContract(id);
      state.extendId = data.baseInfo.extendId;
      setIsEdit(true, data.baseInfo);
    };

    const getBillData = async (params) => {
      // 获取开票信息
      let id = route.query.id;
      const { data } = await proxy.$api.maintenance.getOtherBill(id, params);
      state.billData = data.records;
      state.billTotal = data.total;
    };

    const setIsEdit = (flag, data) => {
      state.columns.map((item) => {
        item.readonly =
          item.prop === "upkeepType" || item.prop === "quotationNo"
            ? true
            : flag;
        item.isUpdate = true;
        if (data && data[item.prop] != null && data[item.prop] != undefined) {
          if (item.type === "upload") {
            let files = [];
            data[item.prop].split(",").map((f) => {
              let name = f.split("/")[f.split("/").length - 1];
              let obj = {
                name: name,
                url: f,
              };
              files.push(obj);
            });
            item.value = files;
          } else {
            item.value = data[item.prop];
            if (item.type === "date") {
              state[item.prop] = data[item.prop];
            }
          }
        }
      });
    };

    const onSubmit = async (row) => {
      // 编辑合同
      let id = route.query.id;
      for (let key in row) {
        if (row[key] instanceof Date) {
          row[key] = proxy.$defined.format(row[key]);
        } else if (Array.isArray(row.manuscript)) {
          let urls = [];
          row.manuscript.map((item) => {
            urls.push(item.url);
          });
          row.manuscript = urls.join(",");
        }
      }
      await proxy.$api.maintenance.editOtherContract(id, row);
      proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
      getContractInfo(route.query.id);
    };

    const areaAttribute = async () => {
      // 区域属性
      let { data } = await proxy.$api.maintenance.areaAttribute();
      state.columns.map((item) => {
        if (item.prop === "areaAttribute") {
          item.data = data;
        }
      });
    };

    const getElevatorList = async (params) => {
      let id = route.query.id;
      const { data } = await proxy.$api.maintenance.getContractEle(id, params);
      state.elevatorData = data.records;
      state.elevatorTotal = data.total;
    };

    const getUpkeepList = async (params) => {
      const { data } = await proxy.$api.maintenance.getOtherData(
        state.baseInfo,
        params
      );
      state.upkeepData = data.records;
      state.upkeepTotal = data.total;
    };

    const handleBillEdit = async (val) => {
      // 新增/编辑开票数据
      let msg = val.id ? "edit" : "new";
      for (let key in val) {
        if (val[key] instanceof Date) {
          val[key] = proxy.$defined.format(val[key]) + " 00:00:00";
        }
      }
      val.id
        ? await proxy.$api.maintenance.editContractBill(val)
        : await proxy.$api.maintenance.addOtherBill(route.query.id, val);
      proxy.$defined.tip(t("i18n." + msg) + t("i18n.success"), "success");
      // 初始化 刷新
      getBillData(state.formInline);
    };

    const handleBillDelete = (row) => {
      proxy.$api.maintenance.delBillOrCollect(row.id, 10).then(() => {
        proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
        getBillData(state.formInline);
      });
    };

    const exportNotBills = async () => {
      // 导出非保养合同开票信息
      let res = await proxy.$api.maintenance.exportNotBills(route.query.id);
      proxy.$defined.exportFunc(res);
    };

    const addNewElevator = async () => {
      // 新增电梯到合同
      var ids = checkSelectEle("selectNewData");
      if (ids !== "") {
        await proxy.$api.maintenance.addContractEle(route.query.id, {
          elevatorStr: ids,
        });
        proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
        state.newElevatorVisible = false;
        getElevatorList(state.paging);
      }
    };

    const addNewContract = async () => {
      // 新增保养合同到合同
      var ids = checkSelectEle("selectNewData");
      if (ids !== "") {
        await proxy.$api.maintenance.addOtherData(state.extendId, {
          ordersStr: ids,
        });
        proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
        state.newUpkeepVisible = false;
        getUpkeepList(state.paging);
      }
    };

    const handleSelectionChange = (val, key) => {
      // 选中电梯数据
      let elevatorIds = [];
      val.map((ele) => {
        elevatorIds.push(ele.id);
      });
      state[key] = elevatorIds;
    };

    const checkSelectEle = (key) => {
      if (state[key].length > 0) {
        return state[key].join(",");
      } else {
        proxy.$defined.tip(t("i18n.selectElevators"));
        return "";
      }
    };

    const getAddEleList = async (params) => {
      // 加载未添加的电梯
      let id = route.query.id;
      params.eleNumber = params.number || "";
      params.projectName = params.projectName || "";
      const { data } = await proxy.$api.maintenance.getContractOtherEle(
        id,
        params
      );
      state.addEleData = data.records;
      state.addEleTotal = data.total;
    };

    const getAddConList = async (params) => {
      // 加载未添加的保养合同
      params.contractNo = params.number || "";
      const { data } = await proxy.$api.maintenance.searchOtherData(
        state.extendId,
        params
      );
      state.addEleData = data.records;
      state.addEleTotal = data.total;
    };

    const handleElevatorDelete = (row) => {
      // 删除合同中的电梯
      proxy.$api.maintenance.delContractEle(row.id, route.query.id).then(() => {
        proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
        getElevatorList(state.formInline);
      });
    };

    const handleUpkeepDelete = (row) => {
      // 删除合同中关联的保养合同
      proxy.$api.maintenance
        .delContract(state.extendId, { orderId: row.id })
        .then(() => {
          proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
          getUpkeepList(state.formInline);
        });
    };

    init();

    return {
      ...toRefs(state),
      getBillData,
      exportNotBills,
      handleBillEdit,
      getElevatorList,
      getUpkeepList,
      getAddEleList,
      getAddConList,
      addNewElevator,
      handleBillDelete,
      handleElevatorDelete,
      handleUpkeepDelete,
      handleSelectionChange,
      setIsEdit,
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
#upkeepContracts {
  .form-button {
    width: 100%;
    margin: 0;
    text-align: center;
    .el-form-item__content {
      width: 100%;
      margin: 0 !important;
    }
  }
  #billTable {
    position: relative;
    .text-right {
      position: absolute;
      right: 45px;
      top: -50px;
      z-index: 2;
      width: 84px;
      height: 16px;
      overflow: hidden;
      opacity: 0;
    }
    .el-dialog {
      .el-form-item,
      .el-form-item__content {
        width: auto;
        display: block;
      }
    }
  }
}
</style>
